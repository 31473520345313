/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Modal from 'react-modal'
import CloseIcon from '@material-ui/icons/Close'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
const ContactModal = ({modalIsOpen,setModalIsOpen}) => {
    const [ message, setMessage] = useState('')
    const customStyles = {
        content : {
            top                   : '56%',
            left                  : '50%',
            right                 : 'auto',
            bottom                : 'auto',
            marginRight           : '-50%',
            transform             : 'translate(-50%, -50%)'
        }
    }

    function closeModal(){
        setModalIsOpen(false)
    }

    const validationSchema = Yup.object().shape({
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        company: Yup.string()
            .required('Company is required'),
        message: Yup.string()
            .required('Message is required'),
        topic: Yup.string()
            .required('Topic is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
 
    })

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Contact modal"
            ariaHideApp={false}
        >
            <div className="">
                <div className="text-right">
                    <button onClick={closeModal}><CloseIcon/></button>
                </div>  
                <div className="w-full"> 
                    <h4 className="text-xl py-2 uppercase tracking-wider">Contact Us</h4>
                    <Formik
                        initialValues={{
                            firstName:'',
                            lastName:'',
                            company: '',
                            message: '',
                            topic: '',
                            email: '',
                        }}
                        validationSchema= {validationSchema}
                        onSubmit={async (values) => {
                            console.log('cc',process.env.GATSBY_SEND_CONTACT_FORM)
                            fetch(`${process.env.GATSBY_SEND_CONTACT_FORM}`, {
                                method: 'POST',
                                headers: {   
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json',
                                },
                                body: JSON.stringify(values)
                            })
                                .then (res=> res.json())
                                .then (data=>{
                                    console.log('data',data)
                                    if(data)
                                        setMessage('Your Application has been submitted. Please check your mailbox.')
                                } 
                                )
                                .catch(error=>{
                                    console.log('errror contact',error)
                                }) 
                                   
                                
                        }}
                    >
                        {({ isValid,dirty }) => ( 
                            <Form>
                                <div className="w-full max-w-lg">
                                    <div className="flex flex-wrap -mx-3">
                                    </div>
                                    <div className="flex flex-wrap -mx-3">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                       Topic
                                            </label>
                                            <div className="relative inline-flex w-full ">
                                                <Field
                                                    id="topic"
                                                    name="topic"
                                                    as="select"
                                                    className="px-4 py-2 border focus:ring-gray-500  focus:border-gray-900 w-full border-gray-300 rounded-md focus:outline-none text-gray-600">
                                                    <option>Choose topic</option>
                                                    <option value={'contact-ricta@rictatechnologies.com'}>Generic Request</option>
                                                    <option value={'contact-ricta@rictatechnologies.com'}>Sales Request</option>
                                                    <option value={'contact-ricta@rictatechnologies.com'}>Feedback and Ideas</option>  
                                                </Field>
                                            </div>
                                            <div className="text-red-500 text-xs flex justify-center items-center">
                                                <ErrorMessage name="topic" />
                                            </div>
                                        </div>
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                       Name
                                            </label>
                                            <div className="">
                                                <div className="lg:col-span-4 sm:text-base text-sm">
                                                    <div className="sm:flex"> 
                                                        <Field
                                                            id="firstName"
                                                            name="firstName" 
                                                            placeholder="First Name"
                                                            type="text"
                                                            className="px-4 mb-1 mr-1 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                                                        />
                                                        <Field type="text"
                                                            id="lastName"
                                                            name="lastName" 
                                                            className="px-4 mb-1 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" placeholder="Last Name"/>
                                                    </div>
                                                    <div className="grid grid-cols-2">
                                                        <div className="text-red-500 text-xs">
                                                            <ErrorMessage name="firstName" />
                                                        </div>
                                                        <div className="text-red-500 text-xs">
                                                            <ErrorMessage name="lastName" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                       Company
                                            </label>
                                            <div className="relative inline-flex w-full ">
                                                <Field
                                                    id="company"
                                                    name="company" 
                                                    placeholder="Company"
                                                    type="text"
                                                    className="px-4 mb-1 mr-1 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                                                />
                                            </div>
                                            <div className="text-red-500 text-xs flex justify-center items-center">
                                                <ErrorMessage name="company" />
                                            </div>
                                        </div>
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                       Email
                                            </label>
                                            <div className="relative inline-flex w-full ">
                                                <Field
                                                    id="email"
                                                    name="email" 
                                                    placeholder="email"
                                                    type="Email"
                                                    className="px-4 mb-1 mr-1 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600"
                                                />
                                            </div>
                                            <div className="text-red-500 text-xs flex justify-center items-center">
                                                <ErrorMessage name="email" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-2">
                                        <div className="w-full px-3">
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                     Message
                                            </label>
                                            <Field 
                                                as= "textarea"
                                                rows="3" 
                                                id="message"
                                                name="message"
                                                className=" px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-base text-sm border-gray-300 rounded-md focus:outline-none text-gray-600" 
                                                placeholder="Enter you message"
                                            />
                                        </div>
                                        <div className="text-red-500 text-xs flex justify-center items-center">
                                            <ErrorMessage name="message" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="flex items-center justify-center">
                                            <button type="submit" className={`${isValid && dirty ? 'bg-mainColor' : 'bg-gray-200'} flex justify-center items-center text-white px-10 py-2 rounded-md focus:outline-none`}>Send</button>
                                        </div>  
                                    </div>
                                    <p className="font-bold text-lg mt-2"> {message}</p>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
   
        </Modal>
    )
}
export default ContactModal